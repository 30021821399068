<template>
  <div id="member-page">
    <v-flex xs12>
      <v-layout wrap class="member" v-if="!isMobile">
        <v-flex xs12 sm3 md3>
          <div class="box">
            <ul>
              <li class="web uppercase" @click="toPage('My-Account')"> {{ $t('account.title')}} </li>
              <li class="web uppercase" @click="toPage('Order')"> {{ $t('account.purchase.title')}} </li>
              <li class="web uppercase" v-if="idBrand === '15'" @click="toPage('Confirm-Payment')"> {{ $t('account.confirm-payment.title')}} </li>
              <li class="web uppercase" @click="toPage('Address')"> {{ $t('account.address.title')}} </li>
              <li class="web uppercase" @click="toPage('Info')">{{ $t('account.personal-info.title')}} </li>
              <li class="web uppercase" @click="toPage('Access-Data')"> {{ $t('account.access.title')}} </li>
              <li class="web uppercase" @click="toPage('Wishlist')"> {{ $t('wishlist.title')}} </li>
              <!-- <li class="web uppercase" @click="toPage('MyReview')"> Notifications </li> -->
              <li class="text-so uppercase" @click="logout()"> {{ $t('signout')}} </li>
            </ul>
          </div>
        </v-flex>
        <v-flex xs12 sm9 md9>
          <div class="box">
            <router-view />
          </div>
        </v-flex>
      </v-layout>
      
      <v-layout wrap class="member" v-else>
        <v-flex xs12>
          <div class="box-2">
            <router-view />
          </div>
        </v-flex>
      </v-layout>
    </v-flex>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'Member',
    computed: {
      ...mapState([
        'isMobile',
        'isLogin'
      ])
    },
    methods: {
      toPage (name) {
        if (this.$route.name !== name) {
          this.$router.push({ name: name });
        }
      }
    }, 
    mounted () {
      if (!this.isLogin) {
        this.$router.push({ name: 'Login'})
      }

      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsLoginPage', false)
    }
  }
</script>

<style scoped>
  .member {
    color: #000000;
    position: relative;
    margin: auto;
    margin-top: 75px;
  }
  
  .box {
    margin-top: 30px;
    padding: 30px;
  }

  .box-2 {
    padding: 0px 15px;
  }

  ul {
    list-style-type: none;
    padding: 0 !important;
  }

  ul > li {
    color: #000000;
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: 700;
    display: block;
    padding-bottom: 0.5em;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .web {
    font-size: 1.2rem !important;
    margin-bottom: 16px;
    padding: 0 !important;
  }

  .web:hover {
    text-decoration: underline;
  }

  .uppercase {
    text-transform: uppercase;
  }

</style>
